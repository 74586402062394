<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="levels.lista.length > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Disciplina</th>
                        <th>Level</th>
                        <th>Avaliado por</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(l, i) in levels.lista" :key="i">
                        <td><strong>{{l.disciplina}}</strong></td>
                        <td>{{l.level}}</td>
                        <td>{{l.usuario_cadastro}}</td>
                        <td>{{dataPt(l.datahora_cadastro)}}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else dense type="error" v-html="'AVALIAÇÃO AUSENTE'"/>
    </div>
</template>

<script>

import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "UsuarioLevelListar",
    props : ['id_usuario'],
    data() {
        return {
            carregando : false,
            levels : {
                lista : []
            }
        }
    },
    computed : {
        ...mapState(['apiUrl'])
    },
    methods : {
        paginar() {
            this.carregando = true
            return axios.post(`${this.apiUrl}usuario/level/rel/listar`, {
                id_usuario : this.id_usuario
            }).then( (res) => {
                this.levels = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'id_usuario' : function () {
            this.paginar()
        }
    },
    activated() {
        this.paginar()
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
