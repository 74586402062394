<template>
    <v-row>
        <v-col cols="12" :sm="usuario.imagem_perfil === 't' ? 9 : 12" :md="usuario.imagem_perfil === 't' ? 10 : 12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-chip small color="green" dark v-if="usuario.ativo === 't'"><v-icon left small>mdi-check-circle</v-icon> Ativo</v-chip>
                    <v-chip small color="red" dark v-else><v-icon left small>mdi-close-circle</v-icon> Inativo</v-chip>
                    <v-btn v-if="permissao('usuario_editar')" exact :to="`/usuario/alterar/${id_usuario}`" icon><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="permissao('usuario_excluir')" exact @click="excluir" icon><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn to="/usuario" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Usuário:</strong> {{usuario.usuario}}</p>
                            <p class="text--primary mb-2"><strong>ID:</strong> {{usuario.id_usuario}}</p>
                            <p class="text--primary mb-2"><strong>Grupo:</strong> <router-link :to="`/usuario/grupo/gerenciar/${usuario.id_grupo}`">{{usuario.grupo}}</router-link></p>
                            <p class="text--primary mb-2"><strong>E-mail:</strong> <a :href="`mailto:${usuario.email}`">{{usuario.email}}</a></p>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Valor da hora aula:</strong> R$ {{numero(usuario.aula_valor)}}</p>
                            <p class="text--primary mb-2"><strong>Cadastrado em:</strong> {{dataPt(usuario.datahora_cadastro)}}</p>
                            <p class="text--primary mb-2"><strong>Último login:</strong> {{dataPt(usuario.ultimo_login) || 'Registro de login não encontrado'}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col sm="3" md="2" v-if="usuario.imagem_perfil === 't'" class="hidden-xs-only">
            <v-card class="mx-auto">
                <img style="width: 100%;" v-auth-image="`${apiUrl}usuario/${usuario.id_usuario}/imagem/thumb.jpg`"/>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-tabs v-model="tab" class="elevation-3" show-arrows dark background-color="primary">
                <!--<v-tab>* Conteúdo ministrado</v-tab>
                <v-tab>* Cursos</v-tab>
                <v-tab>* Alunos</v-tab>
                <v-tab>* Aulas</v-tab>
                <v-tab>* Turmas</v-tab>-->
                <v-tab>Apto a lecionar</v-tab>
                <v-tab>Franquias</v-tab>
                <!--<v-tab>* Disciplinas</v-tab>-->
            </v-tabs>

            <v-tabs-items v-model="tab" class="elevation-3">
                <!--<v-tab-item>
                        <v-divider/>
                        /* Listar aqui o conteúdo ministrado do professor */
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <CursoListar/>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <AlunoListar/>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <AulaListar/>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <TurmaListar/>
                </v-tab-item>-->
                <v-tab-item>
                    <v-btn v-if="usuarioSessao.id_grupo == 1" text :to="`/usuario/level_incluir/${id_usuario}`"><v-icon left>mdi-plus</v-icon>Incluir disciplina/level</v-btn>
                    <v-divider/>
                    <UsuarioLevelListar :id_usuario="parseInt(id_usuario)" />
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <FranquiaListar :filtro="{id_usuario : parseInt(id_usuario)}"/>
                </v-tab-item>
                <!--<v-tab-item>
                    <v-divider/>
                    <DisciplinaListar/>
                </v-tab-item>-->
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>

//import CursoListar from "@/Views/Curso/Component/CursoListar"
//import AulaListar from "@/Views/Aula/Component/AulaListar"
//import TurmaListar from "@/Views/Turma/Component/TurmaListar"
import FranquiaListar from "@/Views/Franquia/Component/FranquiaListar"
//import DisciplinaListar from "@/Views/Disciplina/Component/DisciplinaListar"
//import AlunoListar from "@/Views/Aluno/Component/AlunoListar"
import axios from "axios"
import {mapState} from "vuex"
import UsuarioLevelListar from "@/Views/Usuario/Component/UsuarioLevelListar"

export default {
    name: "UsuarioGerenciar",
    components: {
        UsuarioLevelListar,
        FranquiaListar,
        /* AlunoListar, */
        /*DisciplinaListar, TurmaListar, AulaListar, CursoListar*/},
    props : ['id_usuario'],
    data() {
        return {
            tab : null,
            usuario : {}
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'usuarioSessao'])
    },
    methods : {
        get() {
            return axios.post(`${this.baseUrl}usuario/get`, {id_usuario : this.id_usuario}).then( (res) => {
                this.usuario = res.data
            })
        },
        excluir() {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if(c) {
                return axios.post(`${this.baseUrl}usuario/excluir`, {id_usuario : this.id_usuario}).then( (res) => {
                    if (res.data) {
                        this.$router.push({path:'/usuario'})
                    } else {
                        alert("Erro ao excluir registro")
                    }
                })
            }
        }
    },
    activated() {
        this.get()
    }
}
</script>

<style scoped>

</style>
